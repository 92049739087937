<template>
  <div
    class="pricing-section-five pt-100 pt-150 md-pt-150 sm-pt-80 md-pt-80"
    id="pricing"
  >
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5 order-lg-last">
          <div class="title-style-six ps-5 md-p0">
            <h6 style="font-size: 15px">Confident Pricing</h6>
            <h2>Transform <span>Your </span>Shop</h2>
            <p class="text-xs">
              Rest Assured, e-Matix will never sell you anything you do not
              need.
            </p>
          </div>
          <!-- /.title-style-six -->
        </div>
        <div class="col-lg-7 position-relative">
          <div class="pricing-table-area-five md-mt-60">
            <img
              src="../../assets/images/icon/88.png"
              alt=""
              class="shapes shape-one"
            />
            <img
              src="../../assets/images/shape/160.png"
              alt=""
              class="shapes shape-two"
            />
            <div class="row align-items-center">
              <div class="col-md-6">
                <div class="pr-table-wrapper active js-tilt">
                  <img
                    src="../../assets/images/icon/85.svg"
                    alt=""
                    class="icon"
                  />
                  <div class="pack-name">Basic Plan</div>
                  <ul class="pr-feature">
                    <li>One Administrator</li>
                    <li>Up To 5 Users</li>
                    <li>5gb Data Storage</li>
                  </ul>
                  <div class="price">$89.99</div>
                  <blockquote>For shops under 5 employees</blockquote>
                  <div class="trial-text">
                    Per User per Month (plus $125 per month for server
                    administration)
                  </div>
                  <a
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#contactModal"
                    class="trial-button hover-reverse-gr-bg-one"
                  >
                    <span>Contact Us</span>
                  </a>
                </div>
                <!-- /.pr-table-wrapper -->
              </div>
              <div class="col-md-6">
                <div class="pr-table-wrapper js-tilt sm-mt-80">
                  <img
                    src="../../assets/images/shape/159.svg"
                    alt=""
                    class="popular-badge"
                  />
                  <img
                    src="../../assets/images/icon/86.svg"
                    alt=""
                    class="icon"
                  />
                  <div class="pack-name">Business Plan</div>
                  <ul class="pr-feature">
                    <li>Unlimited Users (5 minimum)</li>
                    <li>30gb Data Storage</li>
                    <li>Email & Live Support.</li>
                    <li>Personalized Integration</li>
                    <li>Server Administration</li>
                  </ul>
                  <div class="price">$79.99</div>
                  <div class="trial-text">Per User per Month</div>
                  <a
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#contactModal"
                    class="trial-button hover-reverse-gr-bg-one gr-bg-one"
                  >
                    <span>Contact Us</span>
                  </a>
                </div>
                <!-- /.pr-table-wrapper -->
              </div>
            </div>
          </div>
          <!-- /.pricing-table-area-five -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PricingArea",
};
</script>
