<template>
  <div
    class="modal fade modal-contact-popup-one"
    id="contactModalThanks"
    tabindex="-1"
    role="dialog"
    aria-labelledby="contactModalThanksTitle"
    aria-hidden="true"
    ref="contactModalThanks"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <main class="main-body modal-content clearfix">
        <button
          style="color: white"
          type="button"
          class="close"
          data-bs-dismiss="modal"
          aria-label="Close"
          id="closeModal2"
        >
          <img src="../../../assets/images/icon/close-w.svg" alt="" />
        </button>
        <div class="left-side">
          <div class="d-flex flex-column justify-content-between h-100">
            <div class="row">
              <div class="col-xl-10 col-lg-8 m-auto">
                <blockquote>
                  “We appreciate your time!  If you need immediate assistance, please call us”
                </blockquote>
                <blockquote>
                  Call us at <a href="tel:+8444362849">844-4EMATIX</a>
                </blockquote>
                <!-- <span class="bio">—</span> -->
              </div>
            </div>
            <img
              src="../../../assets/images/assets/ils_18.svg"
              alt=""
              class="illustration mt-auto"
            />
          </div>
        </div>
        <!-- /.left-side -->
        <div class="right-side">
          <h2 class="form-title">Thank You!  Your information has been received.</h2>
          
        </div>
        <!-- /.right-side -->
      </main>
      <!-- /.main-body -->
    </div>
  </div>
</template>

<script>

export default {
  name: "contactModalThanks",
  methods: {
  },
};
</script>
