<template>
  <footer class="theme-footer-six">
    <div class="inner-container">
      <div class="container">
        <div class="row justify-content-center align-items-center">
          <div class="col-lg-4">
            <div class="logo">
              <router-link to="/">
                <img src="../../../assets/logos/ematix-main-text-logo-for-dark.svg" alt="" style="height: 1.5rem" @click="scrollToTop"/>
              </router-link>
            </div>
          </div>
          <!-- <div class="col-lg-4">
            <div class="title">Find us on Social Media</div>
            <ul class="d-flex justify-content-center social-icon">
              <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
              <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
              <li><a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
            </ul>
          </div> -->
          <div class="col-lg-4">
            <div class="title">We’re always here to help.</div>
            <div class="text-center"><a href="mailto:sales@e-matix.com?subject=Interested in e-Matix" class="email">sales@e-matix.com</a></div>
          </div>
        </div>
        <p class="copyright">Copyright @{{new Date().getFullYear()}} e-Matix Corporation</p>
      </div>
      <img src="../../../assets/images/shape/167.png" alt="" class="shapes shape-one">
    </div> <!-- /.inner-container -->
  </footer> <!-- /.theme-footer-six -->
</template>

<script>
export default {
  name: 'FooterSix',
  methods: {
    scrollToTop() {
      window.scroll({ behavior: 'smooth', left: 0, top: 0 })
      // window.scrollTo(0, 0);
    }
  }
}
</script>