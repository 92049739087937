<template>
  <div
    class="modal fade modal-contact-popup-one"
    id="fileHasherModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="fileHasherTitle"
    aria-hidden="true"
    ref="fileHasherModal"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <main class="main-body modal-content clearfix" style="overflow-y: auto;">
        <button
          type="button"
          class="close"
          data-bs-dismiss="modal"
          aria-label="Close"
          id="closeModal"
          @click="clearData"
        >
          <img src="../../../assets/images/icon/close-w.svg" alt="" />
        </button>
        <!-- /.left-side -->
        <div v-if="true" class="" style="width: 100%; min-height: 625px; height: 60vh; padding: 25px; margin: 25px; text-align: center;">
          <div>
            Secure File Processing in Your Browser
            <br>
            Our platform uses SHA256 checksum for file integrity and quality control. Your files are processed directly in your browser, without uploading them to our server, ensuring their privacy and security.
            <br>
            Share the checksum with collaborators to verify file authenticity and version control.
            <br>
            (file size limit: 2GB)
          </div>
          <div style="margin: 25px;" @click="clearData">
            <div v-bind="getRootProps()">
              <input v-bind="getInputProps()" />
              <p id="dropZone" v-if="isDragActive">Drop the files here ...</p>
              <p id="dropZone" v-else style="cursor: pointer;">Drag a file here, or click to select file</p>
            </div>
            <div style="display: flex; justify-content: center;">
              <button @click="open" class="select-file-hasher-button" style="margin: 25px;">Select File</button>
            </div>
            <div v-if="processing" style="display: flex; justify-content: center;">
              Processing
            </div>
            <div v-if="processing" style="display: flex; justify-content: center;">
              <i class="fa fa-spin fa-spinner" aria-hidden="true"></i>
            </div>
            <div v-if="!processing && sha256Sum !== '' && origFilename !== ''" style="display: flex; justify-content: center;">
              Processing Complete &#x2714;
            </div>
            <div v-if="errorOccurred" style="display: flex; justify-content: center;">
              An error occurred, most likely the file you are submitting is above the 2GB file size limit
            </div>
          </div>
          <div style="text-align: center; overflow-wrap: anywhere; padding-bottom: 25px;">
            <ul>
              <li>
                File Name: {{ origFilename }}
              </li>
              <li>
                SHA-256: {{  sha256Sum }}
              </li>
              <li style="display: flex; justify-content: center;">
                <button id="copyButton" class="select-file-hasher-button" style="width:250px; visibility: hidden;" @click="copy">Copy SHA-256 Hash</button>
              </li>
            </ul>
          </div>
        </div>
        <!-- /.right-side -->
      </main>
      <!-- /.main-body -->
    </div>
  </div>
</template>

<script>
import { onMounted, defineComponent, ref } from 'vue'
import { useDropzone } from 'vue3-dropzone'

export default defineComponent({
  name: "FileHasher",
  components: {

  },
  data() {
    return {

    }
  },
  setup() {
    const sha256Sum = ref('')
    const origFilename = ref('')
    const copiedHash = ref('')
    const isDragActive = ref(false)
    const processing = ref(false)
    const errorOccurred = ref(false)

    onMounted(() => {
      document.getElementById('copyButton').addEventListener('click', function (clicked) {
        return function () {
          if (!clicked) {
            var last = this.innerHTML;
            this.innerHTML = 'Hash Copied	&#x2714;';
            clicked = true;
            setTimeout(function () {
              this.innerHTML = last;
              clicked = false;
            }.bind(this), 2000);
          }
        };
      }(false), this)

      var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function () {
          clearData()
        });
      });

      var target = document.getElementById('fileHasherModal')
      observer.observe(target, { attributes: true, attributeFilter: ['style'] })
    })

    const copy = async () => {
      await navigator.clipboard.writeText(sha256Sum.value)
    }

    function onDrop(acceptFiles, rejectFiles) {
      errorOccurred.value = false
      processing.value = true
      getFileHash(acceptFiles[0])
      console.log(rejectFiles)
    }

    async function getFileHash(file) {
      origFilename.value = ''
      sha256Sum.value = ''
      try {
        origFilename.value = file.name
        var a = new FileReader()
        a.readAsArrayBuffer(file)
        try {
          a.onloadend = async function () {
            let hashPromise
            try {
              hashPromise = await crypto.subtle.digest("SHA-256", a.result);// it outputs a promise
            } catch (error) {
              errorOccurred.value = true
            }
            const hashArray = Array.from(new Uint8Array(hashPromise))
            const hashHex = hashArray
              .map((b) => b.toString(16).padStart(2, "0"))
              .join(""); // convert bytes to hex string
            console.log(hashHex)
            sha256Sum.value = hashHex
            processing.value = false
          }
        } catch (error) {
          errorOccurred.value = true
        }
      } catch (error) {
        processing.value = false
      }
    }
    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });

    function clearData() {
      sha256Sum.value = ''
      origFilename.value = ''
      errorOccurred.value = false
    }

    return {
      getRootProps,
      getInputProps,
      ...rest,
      getFileHash,
      clearData,
      copy,
      isDragActive,
      sha256Sum,
      origFilename,
      copiedHash,
      processing,
      errorOccurred
    }
  },
  watch: {
    sha256Sum(value) {
      let showButton = document.getElementById('copyButton')
      if (value !== '') {
        showButton.style.visibility = 'visible'
      } else {
        showButton.style.visibility = 'hidden'
      }
    }
  },
  methods: {
  }
})
</script>