<template>
  <div
    class="modal fade modal-contact-popup-one"
    id="contactModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="contactModalTitle"
    aria-hidden="true"
    ref="contactModal"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <main class="main-body modal-content clearfix">
        <button
          type="button"
          class="close"
          data-bs-dismiss="modal"
          aria-label="Close"
          id="closeModal"
        >
          <img src="../../../assets/images/icon/close-w.svg" alt="" />
        </button>
        <div class="left-side">
          <div class="d-flex flex-column justify-content-between h-100">
            <div class="row">
              <div class="col-xl-10 col-lg-8 m-auto">
                <blockquote>
                  “Let's schedule a demo and see what e-Matix can do for you”
                </blockquote>
                <blockquote>
                  Call us at <a href="tel:+8444362849">844-4EMATIX</a>
                </blockquote>
                <!-- <span class="bio">—</span> -->
              </div>
            </div>
            <img
              src="../../../assets/images/assets/ils_18.svg"
              alt=""
              class="illustration mt-auto"
            />
          </div>
        </div>
        <!-- /.left-side -->
        <div class="right-side">
          <h2 class="form-title">Contact us</h2>
          <form
            @submit.prevent="onSubmit"
            id="contact-form"
            data-bs-toggle="validator"
          >
            <div class="messages"></div>
            <div class="row controls">
              <div class="col-12">
                <div class="input-group-meta form-group mb-10">
                  <label>First Name*</label>
                  <input
                    type="text"
                    placeholder="Your First Name"
                    name="first_name"
                    required="required"
                    data-error="First Name is required."
                  />
                  <div class="help-block with-errors"></div>
                </div>
              </div>
              <div class="col-12">
                <div class="input-group-meta form-group mb-15">
                  <label>Last Name*</label>
                  <input
                    type="text"
                    placeholder="Your Last Name"
                    name="last_name"
                    required="required"
                    data-error="Last Name is required."
                  />
                  <div class="help-block with-errors"></div>
                </div>
              </div>
              <div class="col-12">
                <div class="input-group-meta form-group mb-15">
                  <label>Company*</label>
                  <input
                    type="text"
                    placeholder="Your Company Name"
                    name="company_name"
                    required="required"
                    data-error="Company Name is required."
                  />
                  <div class="help-block with-errors"></div>
                </div>
              </div>
              <div class="col-12">
                <div class="input-group-meta form-group mb-35">
                  <label>Email*</label>
                  <input
                    type="email"
                    placeholder="Email Address"
                    name="email"
                    required="required"
                    data-error="Valid email is required."
                  />
                  <div class="help-block with-errors"></div>
                </div>
              </div>
              <div class="col-12">
                <div class="input-group-meta form-group mb-35">
                  <label>Phone Number*</label>
                  <input
                    type="text"
                    placeholder="Phone Number"
                    name="phone_number"
                    required="required"
                    data-error="Valid email is required."
                  />
                  <div class="help-block with-errors"></div>
                </div>
              </div>
              <div class="col-12">
                <div class="input-group-meta form-group mb-25">
                  <textarea
                    placeholder="Your message"
                    name="message"
                    required="required"
                    data-error="Please,leave us a message."
                  ></textarea>
                  <div class="help-block with-errors"></div>
                </div>
              </div>
              <div class="col-12">
                <button class="theme-btn-seven w-100">
                  <i v-if="submitting" class="fa fa-spin fa-spinner" aria-hidden="true"></i>
                  Send Message
                </button>
                
              </div>
            </div>
          </form>
        </div>
        <!-- /.right-side -->
      </main>
      <!-- /.main-body -->
    </div>
    <div style="visibility: none;">
      <a
        href="#"
        data-bs-toggle="modal"
        data-bs-target="#contactModalThanks"
        id="aContactModalThanks"
      >
      </a>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "ContactModal",
  data() {
    return {
      submitting: false
    }
  },
  methods: {
    onSubmit() {
      const form = document.getElementById('contact-form');
      this.submitting = true
      let dataToSubmit = {
        email: form.elements['email'].value,
        first_name: form.elements['first_name'].value,
        last_name: form.elements['last_name'].value,
        phone_number: form.elements['phone_number'].value,
        message: form.elements['message'].value
      }
      
      console.log(dataToSubmit)
      
      axios.defaults.baseURL = (process.env.NODE_ENV === 'development') ? 'http://app.e-matix.local/api/v1' : 'https://app.e-matix.com/api/v1'
      axios.post('/leave-message', dataToSubmit, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }).then(response => {
        document.getElementById('closeModal').click()
        console.log(response.data.data)
        this.submitting = false
        setTimeout(() => {
          document.getElementById('aContactModalThanks').click()
        },100)
      }).catch(error => {
        document.getElementById('closeModal').click()
        this.submitting = false
        console.log(error)
      })
    },
  },
};
</script>
