<template>
  <div class="fancy-feature-fourteen pt-250 pt-80 md-pt-80">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-lg-7 col-md-8">
          <div class="title-style-six">
            <h6 style="color: #ff2759; font-size: 15px">Assisted Integrategration</h6>
            <h2>
              e-Matix wants your <br />
              shop to <span>succeed</span>
            </h2>
            <p class="text-xs pe-5 me-5">
              We will work with you every step of the way.
            </p>
          </div>
          <!-- /.title-style-six -->
        </div>

        <div class="col-lg-5 ms-auto">
          <div class="right-side md-mt-60">
            <div class="row">
              <div class="col-md-6">
                <div
                  class="logo-meta lg d-flex align-items-center justify-content-center js-tilt"
                >
                  <img src="../../assets/images/logo/logo-17.svg" alt="" />
                </div>
                <div
                  class="logo-meta d-flex align-items-center justify-content-center js-tilt"
                >
                  <img src="../../assets/images/logo/logo-18.svg" alt="" />
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="logo-meta mt-40 d-flex align-items-center justify-content-center js-tilt"
                >
                  <img src="../../assets/images/logo/logo-19.svg" alt="" />
                </div>
                <div
                  class="logo-meta lg d-flex align-items-center justify-content-center js-tilt"
                >
                  <img src="../../assets/images/logo/logo-20.svg" alt="" />
                  <img
                    src="../../assets/images/shape/154.svg"
                    alt=""
                    class="shapes shape-one"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- /.right-side -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeatureThree",
};
</script>
