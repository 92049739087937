import {createRouter, createWebHistory} from 'vue-router'
// import HomePageOne from '../pages/home';
// import EventPage from '../pages/event';
// import DocLanding from '../pages/doc-landing';
// import CustomerSupport from '../pages/customer-support';
// import ProductLanding from '../pages/product-landing';
import ProductLandingDark from '../pages/product-landing-dark';
// import NoteTaking from '../pages/note-taking';
// import VideoEditor from '../pages/video-editor';
// import AppointmentSchedule from '../pages/appointment-schedule';
// import MobileApp from '../pages/mobile-app';
// import DocSignature from '../pages/doc-signature';
// import WebsiteBuilder from '../pages/website-builder';
// import FormSurvey from '../pages/form-survey.vue';
// import VR_Landing from '../pages/vr-landing';
// import ComingSoon from '../pages/coming-soon';
// import Portfolio_v1 from '../pages/portfolio-v1';
// import Portfolio_v2 from '../pages/portfolio-v2';
// import Portfolio_v3 from '../pages/portfolio-v3';
// import Portfolio_v4 from '../pages/portfolio-v4';
// import Portfolio_v5 from '../pages/portfolio-v5';
// import PortfolioDetails from '../pages/portfolio-details';
// import DynamicPortfolioDetails from '../pages/portfolio-details/dynamic-details';
// import Team_v1 from '../pages/team-v1';
// import Team_v2 from '../pages/team-v2';
// import TeamDetails from '../pages/team-details';
// import TeamDetailsDynamic from '../pages/team-details/dynamic-details';
// import Services_v1 from '../pages/services-v1';
// import Services_v2 from '../pages/services-v2';
// import Services_v3 from '../pages/services-v3';
// import Services_v4 from '../pages/services-v4';
// import ServiceDetails from '../pages/service-details';
// import DynamicServiceDetails from '../pages/service-details/dynamic-details';
// import AboutUsCs from '../pages/about-us(cs)';
// import AboutUsEvent from '../pages/about-us(event)';
// import AboutUsManagement from '../pages/about-us(management)';
// import AboutUsDoc from '../pages/about-us(doc)';
// import PricingCs from '../pages/pricing(cs)';
// import PricingEvent from '../pages/pricing(event)';
// import PricingManagement from '../pages/pricing(management)';
// import ContactCs from '../pages/contact-us(cs)';
// import ContactEvent from '../pages/contact-us(event)';
// import ContactManagement from '../pages/contact-us(management)';
// import ContactDoc from '../pages/contact-us(doc)';
// import SolutionManagement from '../pages/solution(managment)';
// import Product_cs from '../pages/product-cs';
// import FeatureCs from '../pages/feature-cs';
// import Faqs from '../pages/faq-page';
// import FaqDetails from '../pages/faq-details';
// import DynamicFaqDetails from '../pages/faq-details/dynamic-details';
// import TermsCondition from '../pages/terms-condition';
// import LoginPage from '../pages/login';
// import SignUp from '../pages/sign-up';
// import Blog_v1 from '../pages/blog-v1';
// import Blog_v2 from '../pages/blog-v2';
// import Blog_v3 from '../pages/blog-v3';
// import Blog_v4 from '../pages/blog-v4';
// import BlogDetails from '../pages/blog-details';
// import DynamicBlogDetails from '../pages/blog-details/dynamic-details';
// import DocFullWidth from '../pages/doc-fullwidth';
// import DocFullWidthBanner from '../pages/doc-full-width-banner';
// import DocBox from '../pages/doc-box';
// import DocBoxBanner from '../pages/doc-box-banner';
// import ChangeLog from '../pages/changelog';
import NotFound from '../pages/404';


const routes = [
    {
        path: '/',
        name: 'e-Matix Home Page',
        component: ProductLandingDark,
        meta: {
            title: 'e-Matix CORPORATION',
        },
    },
    // {
    //     path: '/homepageone',
    //     name: 'HomePageOne',
    //     component: HomePageOne,
    //     meta: {
    //         title: 'e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/event',
    //     name: 'EventPage',
    //     component: EventPage,
    //     meta: {
    //         title: 'Event || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/doc-landing',
    //     name: 'DocLanding',
    //     component: DocLanding,
    //     meta: {
    //         title: 'Doc Landing || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/customer-support',
    //     name: 'CustomerSupport',
    //     component: CustomerSupport,
    //     meta: {
    //         title: 'Customer Support || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/product-landing',
    //     name: 'ProductLanding',
    //     component: ProductLanding,
    //     meta: {
    //         title: 'Product Landing || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/product-landing-dark',
    //     name: 'ProductLandingDark',
    //     component: ProductLandingDark,
    //     meta: {
    //         title: 'Product Landing Dark || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/note-taking',
    //     name: 'NoteTaking',
    //     component: NoteTaking,
    //     meta: {
    //         title: 'Note Taking || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/video-editor',
    //     name: 'VideoEditor',
    //     component: VideoEditor,
    //     meta: {
    //         title: 'Video Editor || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/appointment-schedule',
    //     name: 'AppointmentSchedule',
    //     component: AppointmentSchedule,
    //     meta: {
    //         title: 'Appointment Schedule || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/mobile-app',
    //     name: 'MobileApp',
    //     component: MobileApp,
    //     meta: {
    //         title: 'Mobile App || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/doc-signature',
    //     name: 'DocSignature',
    //     component: DocSignature,
    //     meta: {
    //         title: 'Doc Signature || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/website-builder',
    //     name: 'WebsiteBuilder',
    //     component: WebsiteBuilder,
    //     meta: {
    //         title: 'Website Builder || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/form-survey',
    //     name: 'FormSurvey',
    //     component: FormSurvey,
    //     meta: {
    //         title: 'Form Survey || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/vr-landing',
    //     name: 'VR_Landing',
    //     component: VR_Landing,
    //     meta: {
    //         title: 'VR Landing || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/coming-soon',
    //     name: 'ComingSoon',
    //     component: ComingSoon,
    //     meta: {
    //         title: 'Coming Soon || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/portfolio-v1',
    //     name: 'Portfolio_v1',
    //     component: Portfolio_v1,
    //     meta: {
    //         title: 'Portfolio V1 || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/portfolio-v2',
    //     name: 'Portfolio_v2',
    //     component: Portfolio_v2,
    //     meta: {
    //         title: 'Portfolio V2 || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/portfolio-v3',
    //     name: 'Portfolio_v3',
    //     component: Portfolio_v3,
    //     meta: {
    //         title: 'Portfolio V3 || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/portfolio-v4',
    //     name: 'Portfolio_v4',
    //     component: Portfolio_v4,
    //     meta: {
    //         title: 'Portfolio V4 || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/portfolio-v5',
    //     name: 'Portfolio_v5',
    //     component: Portfolio_v5,
    //     meta: {
    //         title: 'Portfolio V5 || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/portfolio-details/:id',
    //     name: 'DynamicPortfolioDetails',
    //     component: DynamicPortfolioDetails,
    //     meta: {
    //         title: 'Portfolio Details || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/portfolio-details',
    //     name: 'PortfolioDetails',
    //     component: PortfolioDetails,
    //     meta: {
    //         title: 'Portfolio Details || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/team-v1',
    //     name: 'Team_v1',
    //     component: Team_v1,
    //     meta: {
    //         title: 'Team V1|| e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/team-v2',
    //     name: 'Team_v2',
    //     component: Team_v2,
    //     meta: {
    //         title: 'Team V2 || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/team-details',
    //     name: 'TeamDetails',
    //     component: TeamDetails,
    //     meta: {
    //         title: 'Team Details || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/team-details/:id',
    //     name: 'TeamDetailsDynamic',
    //     component: TeamDetailsDynamic,
    //     meta: {
    //         title: 'Team Details || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/services-v1',
    //     name: 'Services_v1',
    //     component: Services_v1,
    //     meta: {
    //         title: 'Services V1 || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/services-v2',
    //     name: 'Services_v2',
    //     component: Services_v2,
    //     meta: {
    //         title: 'Services V2 || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/services-v3',
    //     name: 'Services_v3',
    //     component: Services_v3,
    //     meta: {
    //         title: 'Services V3 || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/services-v4',
    //     name: 'Services_v4',
    //     component: Services_v4,
    //     meta: {
    //         title: 'Services V4 || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/service-details',
    //     name: 'ServiceDetails',
    //     component: ServiceDetails,
    //     meta: {
    //         title: 'Service Details || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/service-details/:id',
    //     name: 'DynamicServiceDetails',
    //     component: DynamicServiceDetails,
    //     meta: {
    //         title: 'Service Details || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/about-us-cs',
    //     name: 'AboutUsCs',
    //     component: AboutUsCs,
    //     meta: {
    //         title: 'AboutUs Cs || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/about-us-event',
    //     name: 'AboutUsEvent',
    //     component: AboutUsEvent,
    //     meta: {
    //         title: 'AboutUs Event || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/about-us-management',
    //     name: 'AboutUsManagement',
    //     component: AboutUsManagement,
    //     meta: {
    //         title: 'AboutUs Management || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/about-us-doc',
    //     name: 'AboutUsDoc',
    //     component: AboutUsDoc,
    //     meta: {
    //         title: 'AboutUs Doc || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/pricing-cs',
    //     name: 'PricingCs',
    //     component: PricingCs,
    //     meta: {
    //         title: 'Pricing Cs || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/pricing-event',
    //     name: 'PricingEvent',
    //     component: PricingEvent,
    //     meta: {
    //         title: 'Pricing Event || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/pricing-management',
    //     name: 'PricingManagement',
    //     component: PricingManagement,
    //     meta: {
    //         title: 'Pricing Management || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/contact-us-cs',
    //     name: 'ContactCs',
    //     component: ContactCs,
    //     meta: {
    //         title: 'ContactUs Cs || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/contact-us-event',
    //     name: 'ContactEvent',
    //     component: ContactEvent,
    //     meta: {
    //         title: 'ContactUs Event || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/contact-us-management',
    //     name: 'ContactManagement',
    //     component: ContactManagement,
    //     meta: {
    //         title: 'ContactUs Management || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/contact-us-doc',
    //     name: 'ContactDoc',
    //     component: ContactDoc,
    //     meta: {
    //         title: 'ContactUs Doc || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/solution-management',
    //     name: 'SolutionManagement',
    //     component: SolutionManagement,
    //     meta: {
    //         title: 'Solution Management || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/product-cs',
    //     name: 'Product_cs',
    //     component: Product_cs,
    //     meta: {
    //         title: 'Product Cs || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/feature-cs',
    //     name: 'FeatureCs',
    //     component: FeatureCs,
    //     meta: {
    //         title: 'Feature Cs || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/faqs',
    //     name: 'Faqs',
    //     component: Faqs,
    //     meta: {
    //         title: 'Faqs || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/faq-details/:id',
    //     name: 'DynamicFaqDetails',
    //     component: DynamicFaqDetails,
    //     meta: {
    //         title: 'Faq Details || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/faq-details',
    //     name: 'FaqDetails',
    //     component: FaqDetails,
    //     meta: {
    //         title: 'Faq Details || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/terms-condition',
    //     name: 'TermsCondition',
    //     component: TermsCondition,
    //     meta: {
    //         title: 'Terms Condition || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/login',
    //     name: 'LoginPage',
    //     component: LoginPage,
    //     meta: {
    //         title: 'Login || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/register',
    //     name: 'SignUp',
    //     component: SignUp,
    //     meta: {
    //         title: 'Register || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/blog-v1',
    //     name: 'Blog_v1',
    //     component: Blog_v1,
    //     meta: {
    //         title: 'Blog V1 || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/blog-v2',
    //     name: 'Blog_v2',
    //     component: Blog_v2,
    //     meta: {
    //         title: 'Blog V2 || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/blog-v3',
    //     name: 'Blog_v3',
    //     component: Blog_v3,
    //     meta: {
    //         title: 'Blog V3 || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/blog-v4',
    //     name: 'Blog_v4',
    //     component: Blog_v4,
    //     meta: {
    //         title: 'Blog V4 || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/blog-details',
    //     name: 'BlogDetails',
    //     component: BlogDetails,
    //     meta: {
    //         title: 'Blog Details || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/blog-details/:id',
    //     name: 'DynamicBlogDetails',
    //     component: DynamicBlogDetails,
    //     meta: {
    //         title: 'Blog Details || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/doc-full-width',
    //     name: 'DocFullWidth',
    //     component: DocFullWidth,
    //     meta: {
    //         title: 'Doc Full Width || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/doc-full-width-banner',
    //     name: 'DocFullWidthBanner',
    //     component: DocFullWidthBanner,
    //     meta: {
    //         title: 'Doc Full Width Banner || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/doc-box',
    //     name: 'DocBox',
    //     component: DocBox,
    //     meta: {
    //         title: 'Doc Box || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/doc-box-banner',
    //     name: 'DocBoxBanner',
    //     component: DocBoxBanner,
    //     meta: {
    //         title: 'Doc Box Banner || e-Matix CORPORATION',
    //     },
    // },
    // {
    //     path: '/changelog',
    //     name: 'ChangeLog',
    //     component: ChangeLog,
    //     meta: {
    //         title: 'Changelog || e-Matix CORPORATION',
    //     },
    // },
    {
        path: '/not-found',
        name: 'NotFound',
        component: NotFound,
        meta: {
            title: 'NotFound || e-Matix CORPORATION',
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound,
        meta: {
            title: 'NotFound || e-Matix CORPORATION',
        },
    },
   
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
