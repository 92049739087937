<template>
  <div class="fancy-feature-thirteen pt-250 md-pt-110" id="feature">
    <div class="container">
      <div class="row">
        <div class="col-xl-8 col-lg-11 col-md-10 m-auto">
          <div class="title-style-six text-center">
            <h2>
              Immediate Benefits from <span>Data Collection</span> &
              <span>Quality</span>
            </h2>
            <a
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#contactModal"
              class="contact-us-button"
            >
              <span>Contact us to schedule a demo today.</span>
            </a>
            <p class="text-xs">
              e-Matix is designed to use any or all of its features.
            </p>
            <!-- TODO Dhaval add the red check marks before each li like in pricing...see it is using li::before -->
            <ul>
              <li>CNC File Revision Control</li>
              <li>Send and Receive Files</li>
              <li>Automated Data Collection</li>
              <li>Automatic Ballooning of PDF drawings</li>
              <li>Automatic AS9102 and PPAP form generators</li>
              <li>Calibration and Gage Tracking</li>
              <li>Preventative Maintenance</li>

              <li>Digital Shop Floor Dashboards</li>
              <li>Metrics For Everything in <b>your</b> shop</li>
              <li>...and much more</li>
            </ul>
            <p class="text-xs">
              The choice is yours.  Make the right choice.  Choose e-Matix.
            </p>
          </div>
          <!-- /.title-style-six -->
        </div>
      </div>

      <div class="sldier-wrapper mt-110 md-mt-50">
        <div
          id="productScreenSlider"
          class="carousel slide"
          data-bs-ride="carousel"
          data-interval="20000"
        >
          <ol class="carousel-indicators justify-content-between">
            <li
              data-bs-target="#productScreenSlider"
              data-bs-slide-to="0"
              class="active"
            >
              <div class="d-flex">
                <div
                  class="icon d-flex align-items-center justify-content-center"
                >
                  <img src="../../assets/images/icon/81.svg" alt="" />
                </div>
                <p>Easy Backplotting <br />Quickly visualize your programs</p>
              </div>
            </li>
            <li data-bs-target="#productScreenSlider" data-bs-slide-to="1">
              <div class="d-flex">
                <div
                  class="icon d-flex align-items-center justify-content-center"
                >
                  <img src="../../assets/images/icon/81.svg" alt="erty" />
                </div>
                <p>First Article Documentation <br />PPAP and AS9102</p>
              </div>
            </li>
            <li data-bs-target="#productScreenSlider" data-bs-slide-to="2">
              <div class="d-flex">
                <div
                  class="icon d-flex align-items-center justify-content-center"
                >
                  <img src="../../assets/images/icon/81.svg" alt="" />
                </div>
                <p>Automatic Scheduling <br />All in once place</p>
              </div>
            </li>
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <!-- TODO SET NEW IMAGE -->
              <img
                src="../../assets/images/assets/screen_07.png"
                alt=""
                class="m-auto"
              />
            </div>
            <div class="carousel-item">
              <!-- TODO SET NEW IMAGE -->
              <img
                src="../../assets/images/assets/screen_08.png"
                alt=""
                class="m-auto"
              />
            </div>
            <div class="carousel-item">
              <!-- TODO SET NEW IMAGE -->
              <img
                src="../../assets/images/assets/screen_09.png"
                alt=""
                class="m-auto"
              />
            </div>
            <img
              src="../../assets/images/shape/152.svg"
              alt=""
              class="shapes shape-one"
            />
            <img
              src="../../assets/images/shape/153.png"
              alt=""
              class="shapes shape-two"
            />
          </div>
        </div>
      </div>
      <!-- /.sldier-wrapper -->
    </div>
    <ContactModal :dark="dark" />
  </div>
</template>

<script>
import ContactModal from "../common/Modal/ContactModal.vue";

export default {
  name: "FeatureTwo",
  components: {
    // megaMenus,
    ContactModal,
  },
};
</script>
