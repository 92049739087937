<template>
    <div 
        @click="scrollToTop()" 
        :class="['back-to-top-btn', {'go-top': isTop}]"
    >
        <i class='fa fa-arrow-up'></i>
    </div>  
</template>

<script>
export default {
    name: 'BackToTop',
    data (){
        return {
            isTop: false
        }
    },
    methods: {
        scrollToTop() {
        window.scroll({ behavior: 'smooth', left: 0, top: 0 })
        // window.scrollTo(0, 0);
        },
    },
    mounted(){
        const that = this
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if(scrollPos >= 100){
                that.isTop = true
            } else {
                that.isTop = false
            }
        })
    }
}
</script>

<style scoped>
.back-to-top-btn {
    position: fixed;
    cursor: pointer;
    bottom: -100px;
    right: 20px;
    color: #fff;
    background-color: #655c97;
    z-index: 4;
    width: 55px;
    text-align: center;
    height: 55px;
    opacity: 0;
    visibility: hidden;
    border-radius: 50%;
    font-size: 22px;
    transition: 0.6s;
    overflow: hidden;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}
.back-to-top-btn i {
    position: absolute;
    right: 0;
    left: 0;
    top: 45%;
    transform: translateY(-45%);
    text-align: center;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
}
.back-to-top-btn.go-top {
    opacity: 1;
    visibility: visible;
    bottom: 20px;
}
.back-to-top-btn:hover {
    background-color: #ff2759;
    color: #fff;
    transition: 0.4s;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
    transform: translateY(-5px);
}
</style>