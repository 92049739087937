<template>
  <div class="fancy-short-banner-seven pt-250 md-pt-100">
    <div class="container">
      <div class="row">
        <div class="col-xl-8 col-lg-11 m-auto">
          <div class="title-style-six text-center">
            <h2>Get Started with <span>e-Matix</span> Today!</h2>
            <!-- TODO Make Button Style like the others -->
            <a
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#contactModal"
              class="contact-us-button"
            >
              <span>Contact us to schedule a demo today.</span>
            </a>
          </div>
          <!-- /.title-style-six -->
          <!-- <a
            href="#"
            class="d-flex align-items-center ios-button gr-bg-one hover-reverse-gr-bg-one"
          >
            <img src="../../assets/images/icon/apple.svg" alt="" class="icon" />
            <div>
              <span>Download on the</span>
              <strong>App store</strong>
            </div>
          </a> -->
        </div>
      </div>
      <div class="screen-meta js-tilt">
        <img
          src="../../assets/images/assets/screen_10.png"
          alt=""
          class="m-auto"
        />
        <img
          src="../../assets/images/shape/165.svg"
          alt=""
          class="shapes shape-four"
        />
        <img
          src="../../assets/images/shape/166.png"
          alt=""
          class="shapes shape-five"
        />
      </div>
    </div>
    <!-- /.container -->
    <img
      src="../../assets/images/shape/162.svg"
      alt=""
      class="shapes shape-one"
    />
    <img
      src="../../assets/images/shape/163.svg"
      alt=""
      class="shapes shape-two"
    />
    <img
      src="../../assets/images/shape/164.svg"
      alt=""
      class="shapes shape-three"
    />
  </div>
</template>

<script>
export default {
  name: "ShortBanner",
};
</script>
